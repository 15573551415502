@import 'configurable-values';

.leaf-navbar {
  background-color: $background-tux-lightest;

  &.mat-toolbar-single-row {
    padding: 0 2.5rem;
    height: 3.5rem;
  }

  .leaf-navbar-logo-link {
    display: flex;
    align-items: center;
  }
}

.lv-nav-button {
  color: $nuetrals-grey-4;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  padding: 0 10px;
}

.lv-nav-button {
  color: $nuetrals-grey-4;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  padding: 0 10px;
}

.lv-nav-button-active {
  color: $nuetrals-white;
  font-size: 18px;
  font-weight: bold;
}

.leaf-toolbar-fill {
  flex: 1 1 auto;
}

.leaf-navbar-dropdown {
  background: $nuetrals-white;
  color: $background-tux-lightest !important;

  .mat-icon {
    color: $background-tux-lightest !important;
  }

  span {
    color: $background-tux-lightest !important;
  }
}

.leaf-brand-avatar {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 25px;
  background-color: $nuetrals-white;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;

  img {
    width: auto;
    height: auto;
    max-height: 35px;
    max-width: 35px;
    padding-top: 10px;
  }
}

.offer-edit-nav-container {
  @include step-container(35px 0 0 0);
  padding-top: 35px;
}

.offer-edit-nav-logo {
  width: 56px;
  height: 56px;
  margin: 0 8px 16px 0;
  object-fit: contain;
}

.offer-edit-close-icon {
  width: 56px;
  height: 56px;
}

.offer-edit-nav-column {
  width: 100%;
  max-width: 1025px;
}

.offer-edit-nav-title {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.offer-edit-nav-description {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.lb-avatar2 {
  width: 40px;
  height: 40px;
  background-color: $nuetrals-white;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  img {
    max-height: 35px;
    max-width: 35px;
  }
}
