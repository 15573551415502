@import './configurable-values';
.lv-tooltip {
  background-color: $nuetrals-white;
  color: $tux !important;
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.lv-tooltip-dark {
  background-color: $tux !important;
  color: $nuetrals-white !important;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
