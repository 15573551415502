@import '../global/configurable-values';
@import '../global/mixins';

.lv-export-layout {
  .export-question-title {
    font-weight: bold;
    font-size: 1.2rem;
    max-width: 700px;
    margin-top: 20px;
  }

  .export-question-subtitle {
    max-width: 700px;
    margin: 0 !important;
    padding-bottom: 10px;
  }

  div {
    max-width: 700px;
  }

  table {
    width: 700px;
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 1px solid black;
  }
}
