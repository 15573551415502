@import 'configurable-values';

.font-black {
  color: $nuetrals-black-pure !important;
}

.font-white {
  color: $nuetrals-white !important;
}

.font-light-grey {
  color: $nuetrals-unselected-grey !important;
}

.font-grey-4 {
  color: $nuetrals-grey-4 !important;
}

.font-grey-5 {
  color: $nuetrals-grey-5 !important;
}

.font-grey-6 {
  color: $nuetrals-grey-6 !important;
}

.font-orange {
  color: $tint-primary !important;
}

.font-red {
  color: $pastel-red !important;
}

.font-green {
  color: $approve-green !important;
}
