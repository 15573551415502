@import 'configurable-values';

.lv-grid-container {
  max-width: 1600px;
  padding-bottom: 50px;
  width: $content-width;
  margin: auto;
}

.lv-grid-content {
  width: 100%;
  height: 100%;
}

.lv-result-grid {
  mat-grid-tile {
    border-radius: 16px;
    background-color: $nuetrals-white;
    color: $nuetrals-black-pure;
  }
}
