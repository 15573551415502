@import '../global/configurable-values';
@import '../global/mixins';

.lv-survey-wrapper {
  min-height: 625px;
}

.lv-survey-container {
  @include step-container(0 64px 25px 64px);

  .lv-question-container {
    width: 100%;
    padding-bottom: 25px;
    padding-top: 25px;
  }
}

.lv-post-survey-container {
  @include step-container(20px 64px 0 64px);

  .lv-post-tab-container-border {
    border-bottom: $nuetrals-grey-4 1px solid;
  }

  .lv-post-tab-container {
    width: 100%;
    padding-bottom: 15px;

    .lv-post-tab-title {
      width: 200px;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
    }

    .lv-post-tab-subtitle {
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
    }

    .lv-post-tab-info-icon {
      margin-bottom: 15px;
    }
  }

  .lv-question-container {
    width: 100%;
    padding-bottom: 25px;
    padding-top: 25px;
  }
}

.lv-question-box {
  align-self: center;
  width: 100%;
  border: 2px solid $tux;
  padding-right: 34px;
  padding-left: 14px;
  border-radius: 8px;
  height: 80px;
  text-align: center;
  max-width: $maxWidth;
}

.lv-question-input-row {
  margin-top: 10px;
  width: 100%;

  .lv-question-number {
    font-size: 21px;
    font-weight: bold;
    line-height: 27px;
  }
}

.lv-question-input-label {
  max-width: 500px;
  width: 100%;

  .mat-form-field {
    width: 100%;
    padding-bottom: 0;
  }
}

.lv-question-input-type {
  width: 100%;
  max-width: 200px;

  .mat-form-field {
    width: 100%;
    padding-bottom: 0;
  }
}

.lv-question-input-type-edit {
  max-width: 200px;
  padding-bottom: 15px;
  padding-left: 5%;
  line-height: 1.75;

  .lv-question-input-type-label {
    font-size: 10px;
    font-weight: bold;
  }
}

.lv-question-single-box {
  align-self: center;
  width: 100%;
  border: 2px solid $tux;
  padding-right: 34px;
  padding-left: 14px;
  border-radius: 8px;
  min-height: 830px;
  text-align: center;
  max-width: $maxWidth;
}

.lv-question-single-box-display {
  align-self: center;
  width: 100%;
  border: 2px solid $tux;
  padding-right: 34px;
  padding-left: 14px;
  border-radius: 8px;
  min-height: 80px;
  text-align: center;
  max-width: $maxWidth;
}

.lv-question-display {
  font-size: 18px;
  width: 75%;

  text-align: left;
  color: $tux;
}

.lv-question-title {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: bold;
  text-align: left;
  margin-left: 6%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lv-select-answer-row {
  margin-left: 6%;

  .mat-form-field.mat-form-field .mat-form-field-label {
  }

  .mat-icon-button.mat-button-disabled.mat-button-disabled {
    color: $nuetrals-grey-5;
  }
}

.lv-question-text-input {
  width: 80%;
}

.lv-question-checkbox-row {
  width: 100%;
  text-align: left;
  margin-left: 6%;
  margin-top: 20px;
  margin-bottom: 20px;

  .mat-checkbox {
    padding-left: 12px;
    font-size: 16px;
    font-weight: bold;
  }

  .mat-checkbox .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: $nuetrals-white;
  }

  .mat-checkbox .mat-checkbox-frame .mat-checked {
    border-color: $tint-primary;
    background-color: $tint-primary;
  }
}

.lv-question-buttons-row {
  width: 94%;
  text-align: right;
  margin-left: 6%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lv-conditional-section {
  margin-left: 6%;
}

.lv-conditional-row {
  max-width: 600px;
}

.lv-conditional-label {
  text-align: left;
  width: 50%;
  max-width: 268px;
  font-size: 18px;
  color: $nuetrals-black-pure;
  line-height: 24px;
}

.lv-conditional-select {
  width: 50%;
  max-width: 268px;
}

.lv-question-validation-section {
  margin-left: 6%;
}

.lv-question-preview-image-container {
  width: 180px;
  height: auto;
  max-height: 180px;
  border-radius: 8px;
  padding-bottom: 10px;

  img {
    border-radius: 8px;
    width: 180px;
    height: auto;
    max-height: 180px;
  }
}

.lv-question-edit-content {
  margin-left: 6%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

/* Drag and Drop. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  background: transparent;
  //border: $dark-grey 3px $light-grey;
  //box-sizing: border-box;
  //border-radius: 4px;
  //box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  //0 8px 10px 1px rgba(0, 0, 0, 0.14),
  //0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.lv-drag-custom-placeholder {
  background: $tint-primary-50;
  border: $dark-grey 3px $nuetrals-grey-5;
  border-radius: 4px;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
