@import 'configurable-values';
// MAT SELECT OVERWRITE //

.mat-select-panel {
  background: $nuetrals-white !important;

  .mat-option {
    color: $nuetrals-black-pure;
  }

  .mat-checkbox {
    color: $nuetrals-black-pure;
  }

  .mat-pseudo-checkbox {
    color: $nuetrals-black-pure;
  }

  .mat-option:hover {
    background: $tint-primary-50;
  }
}

.mat-select-arrow {
  color: $nuetrals-black-pure;
}

.mat-select-value {
  color: $nuetrals-black-pure;
}
