@import 'configurable-values';

.bg-tux {
  background: $nuetrals-black-pure !important;
}

.bg-white {
  background: $nuetrals-white !important;
}

.bg-orange {
  background: $tint-orange !important;
}
